import React from "react";
import { Chart, ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";
Chart.register(ArcElement);

const Circle = (numbers, color) => {
  const dataText = numbers.numbers;

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    responsive: true,
    cutoutPercentage: 75,
    title: {
      display: false,
    },

    maintainAspectRatio: false,
  };

  const data = {
    datasets: [
      {
        data: [100],
        backgroundColor: [numbers.color],
        borderColor: ["rgba(47, 63, 159, 1)"],
        borderWidth: 0,
      },
    ],
  };
  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 130).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        var text = dataText,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = (height - 15) / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  return (
    <>
      <div style={{ maxWidth: "200px" }}>
        <Doughnut
          data={data}
          options={options}
          plugins={plugins}
          style={{ width: "120px", height: "120px" }}
        />
      </div>
    </>
  );
};

export default Circle;
