import { css } from "@emotion/react";

export const color = {
  space: "rgb(0,19,25)",
  spaceMedium: "rgb(102,113,117)",
  spaceLight: "rgb(153,161,163)",
  spaceLighter: "rgb(204,208,209)",
  spaceLightest: "rgb(229,231,232)",
  spaceMediumAlpha: "rgba(0,19,25,0.6)",
  spaceLightAlpha: "rgba(0,19,25,0.4)",
  spaceLighterAlpha: "rgba(0,19,25,0.2)",
  spaceLightestAlpha: "rgba(0,19,25,0.1)",

  stardust: "rgb(245,245,246)",
  stardustLight: "rgb(247,247,248)",
  stardustLighter: "rgb(251,251,252)",
  stardustLightAlpha: "rgba(245,245,246,0.8)",
  stardustLighterAlpha: "rgba(245,245,246,0.32)",

  // blue
  earth: "rgb(0,99,146)",
  earthLight: "rgb(51,130,168)",
  earthLighter: "rgb(153,193,211)",
  earthLightest: "rgb(214,230,238)",
  earthLightAlpha: "rgba(0,99,146, 0.8)",
  earthLighterAlpha: "rgba(0,99,146, 0.4)",
  earthLightestAlpha: "rgba(0,99,146, 0.16)",
  earthFocusAlpha: "rgba(0,99,146, 0.24)",

  sky: "rgb(224,246,253)",
  skyLight: "rgb(240,251,254)",

  // green
  titan: "rgb(132,173,30)",
  titanLight: "rgb(157,189,75)",
  titanLighter: "rgb(206,222,165)",
  titanLightest: "rgb(235,242,219)",
  titanLightAlpha: "rgba(132,173,30, 0.8)",
  titanLighterAlpha: "rgba(132,173,30, 0.4)",
  titanLightestAlpha: "rgba(132,173,30, 0.16)",
  titanFocusAlpha: "rgba(132,173,30, 0.24)",

  // red
  mars: "rgb(254,74,73)",
  marsLight: "rgb(254,110,109)",
  marsLighter: "rgb(255,183,182)",
  marsLightest: "rgb(254,226,226)",
  marsLightAlpha: "rgba(254,74,73,0.8)",
  marsLighterAlpha: "rgba(254,74,73,0.4)",
  marsLightestAlpha: "rgba(254,74,73,0.16)",
  marsFocusAlpha: "rgba(254,74,73,0.24)",

  // orange
  sun: "rgb(245,153,50)",
  sunLight: "rgb(247,173,91)",
  sunLighter: "rgb(251,214,173)",
  sunLightest: "rgb(253,239,222)",
  sunLightAlpha: "rgba(245,153,50, 0.8)",
  sunLighterAlpha: "rgba(245,153,50, 0.4)",
  sunLightestAlpha: "rgba(245,153,50, 0.16)",
  sunFocusAlpha: "rgba(245,153,50, 0.24)",

  facebook: "rgb(23,119,242)",
  facebookLight: "rgb(69,146,245)",
  facebookFocusAlpha: "rgba(23,119,242,0.24)",

  twitter: "rgb(56,161,243)",
  twitterLight: "rgb(96,180,245)",
  twitterFocusAlpha: "rgba(56,161,243,0.24)",
};

export const fontStack = `'Font Name', -apple-system, BlinkMacSystemFont,
'avenir next', avenir, 'helvetica neue', helvetica, ubuntu, roboto, noto,
'segoe ui', arial, sans-serif`;

export const fontSize = {
  12: "0.75rem",
  14: "0.875rem",
  16: "1rem",
  18: "1.125rem",
  20: "1.25rem",
  24: "1.5rem",
  28: "1.75rem",
  32: "2rem",
  56: "3.5rem",
};

export const shadow = {
  text: `0 1px 0 ${color.spaceLightestAlpha}`,
  light: `0 1px 4px ${color.spaceLightestAlpha}`,
  strong: `0 1px 2px ${color.spaceLighterAlpha}, 0 4px 12px ${color.spaceLightestAlpha}`,
};

export const space = {
  0: "0",
  4: "0.25rem",
  8: "0.5rem",
  12: "0.75rem",
  16: "1rem",
  24: "1.5rem",
  32: "2rem",
  44: "2.75rem",
  48: "3rem",
  56: "3.5rem",
  64: "4rem",
  96: "6rem",
  128: "8rem",
  256: "16rem",
  512: "32rem",
  768: "48rem",
};

export const radius = {
  sm: space[4],
  md: space[8],
  lg: space[12],
};

export const easing = {
  easeInQuad: "cubic-bezier(.55, .085, .68, .53)",
  easeInCubic: "cubic-bezier(.550, .055, .675, .19)",
  easeInQuart: "cubic-bezier(.895, .03, .685, .22)",
  easeInQuint: "cubic-bezier(.755, .05, .855, .06)",
  easeInExpo: "cubic-bezier(.95, .05, .795, .035)",
  easeInCirc: "cubic-bezier(.6, .04, .98, .335)",

  easeOutQuad: "cubic-bezier(.25, .46, .45, .94)",
  easeOutCubic: "cubic-bezier(.215, .61, .355, 1)",
  easeOutQuart: "cubic-bezier(.165, .84, .44, 1)",
  easeOutQuint: "cubic-bezier(.23, 1, .32, 1)",
  easeOutExpo: "cubic-bezier(.19, 1, .22, 1)",
  easeOutCirc: "cubic-bezier(.075, .82, .165, 1)",

  easeInOutQuad: "cubic-bezier(.455, .03, .515, .955)",
  easeInOutCubic: "cubic-bezier(.645, .045, .355, 1)",
  easeInOutQuart: "cubic-bezier(.77, 0, .175, 1)",
  easeInOutQuint: "cubic-bezier(.86, 0, .07, 1)",
  easeInOutExpo: "cubic-bezier(1, 0, 0, 1)",
  easeInOutCirc: "cubic-bezier(.785, .135, .15, .86)",
};

export const easingFunctions = {
  linear: (t) => t,
  easeInQuad: (t) => t * t,
  easeOutQuad: (t) => t * (2 - t),
  easeInOutQuad: (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
  easeInCubic: (t) => t * t * t,
  easeOutCubic: (t) => --t * t * t + 1,
  easeInOutCubic: (t) =>
    t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
  easeInQuart: (t) => t * t * t * t,
  easeOutQuart: (t) => 1 - --t * t * t * t,
  easeInOutQuart: (t) =>
    t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t,
  easeInQuint: (t) => t * t * t * t * t,
  easeOutQuint: (t) => 1 + --t * t * t * t * t,
  easeInOutQuint: (t) =>
    t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t,
};

export const duration = 200;
export const transition = `${duration}ms ${easing.easeInOutCubic}`;

export const lineHeight = {
  solid: 1,
  title: 1.25,
  titleLg: 1.125,
  copy: 1.5,
};

export const fontWeight = {
  regular: 400,
  semiBold: 600,
  bold: 700,
};

export const sizes = {
  mobile: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopM: 1280,
  laptopL: 1440,
  desktop: 2560,
};

export const device = {
  mobile: `(min-width: ${sizes.mobile / 16}em)`,
  mobileM: `(min-width: ${sizes.mobileM / 16}em)`,
  mobileL: `(min-width: ${sizes.mobileL / 16}em)`,
  tablet: `(min-width: ${sizes.tablet / 16}em)`,
  laptop: `(min-width: ${sizes.laptop / 16}em)`,
  laptopM: `(min-width: ${sizes.laptopM / 16}em)`,
  laptopL: `(min-width: ${sizes.laptopL / 16}em)`,
  desktop: `(min-width: ${sizes.desktop / 16}em)`,
};

export const fullBleed = css`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`;

export const resetFullBleed = css`
  width: auto;
  position: relative;
  left: auto;
  right: auto;
  margin-left: 0;
  margin-right: 0;
`;
