import React, { createContext, useContext } from "react";
import Cookies from "js-cookie";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const hasSpotterCookie = Cookies.get("spToken") ? true : false;

  return (
    <>
      <AuthContext.Provider value={{ hasCookie: hasSpotterCookie }}>
        {children}
      </AuthContext.Provider>
    </>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  return { context };
}
