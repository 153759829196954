import { sharedProps } from "../utils/misc";
import { InputAdornment } from "@material-ui/core";
import SignpostIcon from "@mui/icons-material/Signpost";
import NumbersIcon from "@mui/icons-material/Numbers";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import EmailIcon from "@mui/icons-material/Email";
import BadgeIcon from "@mui/icons-material/Badge";
import { t } from "../translationHelper";

const iconForField = (Icon) => {
  return {
    endAdornment: (
      <InputAdornment position="start">
        <Icon />
      </InputAdornment>
    ),
  };
};

export const editFormTemplate = (formik) => {
  return {
    title: "Edit Form",
    Fields: [],
  };
};

export const editLocationsTemplate = (formik) => {
  return {
    title: "Edit Owner",
    Fields: [
      {
        name: "street",
        type: "text",
        title: "street",
        id: "street",
        label: t("street"),
        autoComplete: "Street",
        InputProps: iconForField(SignpostIcon),
        ...sharedProps,
        value: formik.values.street,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.street),
        helperText: formik.errors.street,
      },
      {
        name: "number",
        type: "text",
        title: "number",
        id: "number",
        label: t("number"),
        autoComplete: "Number",
        ...sharedProps,
        InputProps: iconForField(NumbersIcon),
        value: formik.values.number,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.number),
        helperText: formik.errors.number,
      },
      {
        name: "city",
        type: "text",
        title: "city",
        id: "city",
        label: t("city"),
        autoComplete: "city",
        ...sharedProps,
        InputProps: iconForField(LocationCityIcon),
        value: formik.values.city,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.city),
        helperText: formik.errors.city,
      },
      {
        name: "zip",
        type: "text",
        title: "zip",
        id: "zip",
        label: t("zip"),
        autoComplete: "Zip",
        ...sharedProps,
        InputProps: iconForField(LocalShippingIcon),
        value: formik.values.zip,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.zip),
        helperText: formik.errors.zip,
      },
    ],
  };
};

export const editOwnerTemplate = (formik) => {
  return {
    title: "Edit Owner",
    Fields: [
      {
        name: "email",
        type: "text",
        title: "email",
        id: "email",
        label: t("email"),
        autoComplete: "email",
        ...sharedProps,
        InputProps: iconForField(EmailIcon),
        value: formik.values.email,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.email),
        helperText: formik.errors.email,
      },
      {
        name: "first_name",
        type: "text",
        title: "first_name",
        id: "first_name",
        label: t("first_name"),
        autoComplete: "first_name",
        InputProps: iconForField(BadgeIcon),
        ...sharedProps,
        value: formik.values.first_name,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.first_name),
        helperText: formik.errors.first_name,
      },
      {
        name: "last_name",
        type: "text",
        title: "last_name",
        id: "last_name",
        label: t("last_name"),
        autoComplete: "last_name",
        InputProps: iconForField(BadgeIcon),
        ...sharedProps,
        value: formik.values.last_name,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.last_name),
        helperText: formik.errors.last_name,
      },
    ],
  };
};
