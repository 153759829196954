import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackbarAlert({
  content,
  variant,
  display,
  handleClose,
}) {
  if (!content) return null;

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        onClick={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={display}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert
          style={{ marginTop: '8em', marginRight: '13.8em' }}
          onClose={handleClose}
          severity={variant}
          sx={{ width: '100%' }}
        >
          {content}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

/* <Alert severity="error">This is an error message!</Alert>
<Alert severity="warning">This is a warning message!</Alert>
<Alert severity="info">This is an information message!</Alert>
<Alert severity="success">This is a success message!</Alert> */
