import * as yup from "yup";
import { t } from "../translationHelper";

// yarn i18nexus pull --api-key msdZDgcNXrfKy0t2vtYxXg

export function validationsFor(action) {
  switch (action) {
    case "SIGN_IN":
      const signUpSchema = yup.object({
        email: yup
          .string(t("enter_email"))
          .email(t("enter_valid_email"))
          .required(t("email_required")),
        password: yup
          .string(t("enter_password"))
          .required(t("password_required")),
      });
      return signUpSchema;

    case "EDIT_PROFILE":
      const editSchema = yup.object({
        name: yup
          .string(t("enter_business_name"))
          .required(t("business_name_required")),
        street: yup
          .string(t("enter_business_street"))
          .required(t("business_street_required")),
        number: yup
          .string(t("enter_business_number"))
          .required(t("business_number_required")),
        city: yup
          .string(t("enter_business_city"))
          .required(t("business_city_required")),
        zip: yup
          .string(t("enter_business_zip"))
          .required(t("business_zip_required")),
        email: yup.string(t("enter_email")).required(t("email_required")),
        first_name: yup
          .string(t("enter_first_name"))
          .required(t("first_name_required")),
        last_name: yup
          .string(t("enter_last_name"))
          .required(t("last_name_required")),
      });
      return editSchema;

    case "EDIT_PASSWORD":
      const editPasswordSchema = yup.object({
        password: yup
          .string(t("enter_password"))
          .required(t("password_required")),
        new_password: yup
          .string(t("enter_new_password"))
          .required(t("new_password_required")),
        confirm_new_password: yup
          .string(t("enter_confirm_new_password"))
          .required(t("confirm_new_password_required")),
      });
      return editPasswordSchema;

    case "ADD_EMPLOYEE":
      const addEmployeeSchema = yup.object({
        email: yup
          .string(t("enter_email"))
          .email(t("enter_valid_email"))
          .required(t("email_required")),
        first_name: yup
          .string(t("enter_first_name"))
          .required(t("first_name_required")),
        last_name: yup
          .string(t("enter_last_name"))
          .required(t("last_name_required")),
      });
      return addEmployeeSchema;

    case "ADD_LOCATION":
      const addLocationSchema = yup.object({
        title: yup
          .string(t("location_title"))
          .required(t("location_title_required")),
        street: yup.string(t("street")).required(t("street_required")),
        number: yup.string(t("number")).required(t("number_required")),
        city: yup.string(t("city")).required(t("city_required")),
        zip: yup.string(t("zip")).required(t("zip_required")),
      });
      return addLocationSchema;

    case "SIGNUP":
      const signupSchema = yup.object({
        name: yup
          .string(t("enter_business_name"))
          .required(t("business_name_required")),
        street: yup
          .string(t("enter_business_street"))
          .required(t("business_street_required")),
        number: yup
          .string(t("enter_business_number"))
          .required(t("business_number_required")),
        city: yup
          .string(t("enter_business_city"))
          .required(t("business_city_required")),
        zip: yup
          .string(t("enter_business_zip"))
          .required(t("business_zip_required")),
        email: yup.string(t("enter_email")).required(t("email_required")),
        first_name: yup
          .string(t("enter_first_name"))
          .required(t("first_name_required")),
        last_name: yup
          .string(t("enter_last_name"))
          .required(t("last_name_required")),
      });
      return signupSchema;

    case "BUSINESS":
      const businessSchema = yup.object({
        name: yup
          .string(t("enter_business_name"))
          .required(t("business_name_required")),
        street: yup
          .string(t("enter_business_street"))
          .required(t("business_street_required")),
        number: yup
          .string(t("enter_business_number"))
          .required(t("business_number_required")),
        city: yup
          .string(t("enter_business_city"))
          .required(t("business_city_required")),
        zip: yup
          .string(t("enter_business_zip"))
          .required(t("business_zip_required")),
      });
      return businessSchema;

    case "OWNER":
      const ownerSchema = yup.object({
        email: yup.string(t("enter_email")).required(t("email_required")),
        first_name: yup
          .string(t("enter_first_name"))
          .required(t("first_name_required")),
        last_name: yup
          .string(t("enter_last_name"))
          .required(t("last_name_required")),
      });
      return ownerSchema;
  }
}
