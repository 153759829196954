import styled from "@emotion/styled";
import { color } from "../../../theme";
import { H4 } from "../Heading";
import { Card } from "@mui/material";

import Circle from "../Doughnut";
const Stats = ({ employees, total, locations, monthly, weekly }) => {
  return (
    <>
      <Card style={cardStyle}>
        <StatsRow>
          <StatsColumn>
            <H4>Active Locations</H4> {/* //TODO: translation text */}
          </StatsColumn>
          <StatsColumn>
            <H4>Active Users</H4> {/* //TODO: translation text */}
          </StatsColumn>
          <StatsColumn>
            <H4>Completed Spots</H4> {/* //TODO: translation text */}
          </StatsColumn>
        </StatsRow>
        <StatsRow>
          <StatsColumnTwo>
            <StatsPosition>
              <Circle numbers={locations} color={"rgba(145,190,35, 0.75)"} />
            </StatsPosition>
          </StatsColumnTwo>
          <StatsColumnTwo>
            <StatsPosition>
              <Circle numbers={employees} color={"rgba(23,44,83, 0.75)"} />
            </StatsPosition>
          </StatsColumnTwo>
          <StatsColumnTwo>
            <StatsPoints>
              <Circle numbers={total} color={"rgba(255,171,0, 0.75)"} />
            </StatsPoints>
          </StatsColumnTwo>
        </StatsRow>
      </Card>
    </>
  );
};

export default Stats;

const cardStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  flexWrap: "wrap",
  borderRadius: "4px",
  paddingBottom: "1rem",
  paddingTop: "1rem",
  boxShadow: "none",
  marginTop: "3rem",
};

// Rank styles
export const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 4px;
  padding-bottom: 1rem;
  padding-top: 1rem;
`;

export const StatsPosition = styled.div`
  text-align: center;
  color: ${color.spaceMedium};
`;
export const StatsPoints = styled.div`
  text-align: center;
  color: ${color.spaceMedium};
  margin-bottom: 10px;
`;
export const StatsRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;
export const StatsColumn = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`;
export const StatsColumnTwo = styled.div`
  display: flex;
  height: 120px;
  align-items: center;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`;
