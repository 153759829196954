import { t } from "../../translationHelper";
import * as React from 'react';
import LocationCard from './location';
import AddLocationPopup from '../AddLocationPopup';
import { Map } from 'pigeon-maps';
import greenMarker from '../../images/map_pin_green.svg';
import orangeMarker from '../../images/map_pin_orange.svg'


export function PigeonMap({ locations }) {
  const [coords, setCoords] = React.useState(null);

  React.useEffect(() => {
    if (!locations[0]) return;
    const coords = [parseFloat(locations[0].lat), parseFloat(locations[0].lon)];
    setCoords(coords);
  }, [locations]);

  // const iconPerson = new L.Icon({
  //   iconUrl: require('../../images/logo-blue.svg'),
  //   iconRetinaUrl: require('../../images/logo-blue.svg'),
  //   iconSize: new L.Point(60, 75),
  //   className: 'leaflet-div-icon'
  // });
  const Marker = ({ left, top, style, children, connect_status }) => (
    <div style={{
      position: 'absolute',
      left: left - 15,
      top: top - 30,
      width: 30,
      height: 30,
      ...(style || {})
    }}><img src={connect_status === 'approved' ? greenMarker : orangeMarker }/></div>
  )

  return (
    <div>
      {locations && coords && (
        <Map height={300} defaultCenter={coords} defaultZoom={11}>
          <Marker width={50} anchor={coords} connect_status={locations[0].connect_status} />
          {/* add a marker for each location */}
          {locations
            .filter((loc) => loc.lat && loc.lon)
            .slice(1)
            .map((location, index) => (
              <Marker
                key={index}
                anchor={[parseFloat(location.lat), parseFloat(location.lon)]}
                connect_status={location.connect_status}
              />
            ))}
        </Map>
      )}
    </div>
  );
}

export const LocationList = ({ locations, setLocationsData, businessId }) => {
  return (
    <>
      <AddLocationPopup
        setLocationsData={setLocationsData}
        businessId={businessId}
      ></AddLocationPopup>

      {locations.length > 0 && (
        <h1 style={{ marginTop: "50px", marginLeft: "5%", marginRight: "5%" }}>
          Location List
        </h1> //TODO: translation text
      )}
      <div
        id="main-wrapper"
        style={{ display: "flex", marginLeft: "5%", marginRight: "5%" }}
      >
        <div
          style={{
            borderRadius: "5px",
            width: "35%",
          }}
        >
          <div>
            {locations &&
              locations.length > 0 &&
              locations
                .sort(function(a, b) {
                  return a.connect_status > b.connect_status;
                })
                // .filter((loc) => loc.connect_status === 'approved')
                .map((location, index) => (
                  <div
                    className="location-wrapper"
                    style={{
                      display: "inline-block",
                      margin: "8px",
                      padding: "5px",
                    }}
                    key={index}
                  >
                    <LocationCard
                      setLocationsData={setLocationsData}
                      businessId={businessId}
                      location={location}
                    ></LocationCard>
                  </div>
                ))}
            
          </div>
        </div>
        <div>
          <PigeonMap locations={locations} />
          <br></br>
          {!locations || locations.length === 0 ? (
            <h4 className="fix-this" style={{ color: "white !important;" }}>
              {t("no_locations_text")}
            </h4>
          ) : (
            <h4 className="fix-this"> {t("no_locations_text")}</h4>
          )}{" "}
        </div>
      </div>
    </>
  );
};
