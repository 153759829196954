import React from "react";
import { Line } from "react-chartjs-2";
import { H3 } from "../Heading";
import { t } from "../../../translationHelper";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const Graph = (data) => {
  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        suggestedMax: 10,
        min: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },

    maintainAspectRatio: false,
  };

  return (
    <>
      <H3 style={{ marginBottom: "10px" }}>Spots in the last week</H3>
      <div style={{ maxHeight: "220px" }}>
        <Line
          options={options}
          style={{ height: "220px" }}
          datasetIdKey="id"
          data={{
            labels: [
              t("mon"),
              t("tue"),
              t("wed"),
              t("thu"),
              t("fri"),
              t("sat"),
              t("sun"),
            ],
            datasets: [
              {
                id: 1,
                label: "",

                data: [
                  data.data["1"],
                  data.data["2"],
                  data.data["3"],
                  data.data["4"],
                  data.data["5"],
                  data.data["6"],
                  data.data["7"],
                ],
                backgroundColor: "rgb(47, 63, 159)",
                borderColor: "rgb(47, 63, 159",
                pointRadius: 1,
              },
            ],
          }}
        />
      </div>
    </>
  );
};

export default Graph;
