import { useApi } from "../../hooks/useApi";
import { API } from "../../utils/api";
import Grid from "@material-ui/core/Grid";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddEmployeePopup from "../../components/AddEmployeePopup";

const Business = () => {
  const { t } = useTranslation("translation");
  const [businessEmployees] = useApi("loadBusinessEmployees");
  const [employeesTableRows, setEmployeesTableRows] = useState(null);

  const updateBusinessEmployeesTable = (businessEmployees) => {
    const data = businessEmployees.map((employee) => {
      return {
        id: employee.id,
        col1: employee.first_name,
        col2: employee.last_name,
        col3: employee.email,
        col4: "SmartSpotter",
        col5: employee.created_at.slice(0, 10),
      };
    });
    setEmployeesTableRows(data);
  };

  useEffect(() => {
    if (businessEmployees) {
      updateBusinessEmployeesTable(businessEmployees);
    }
  }, [businessEmployees]);

  const removeEmployee = async (params) => {
    if (!params.id) return;
    const res = await API.removeEmployeeFromBusiness({ id: params.id });
    updateBusinessEmployeesTable(res.data);
  };

  const renderDetailsButton = (params) => {
    return (
      <div>
        <button>
          <DeleteIcon
            onClick={(e) => removeEmployee(e)}
            style={{ color: "red" }}
          ></DeleteIcon>
        </button>
      </div>
    );
  };

  const tableHeaders = [
    {
      field: "col1",
      headerName: t("first_name"),
      width: 120,
      headerClassName: "employee-table-header",
    },
    {
      field: "col2",
      headerName: t("last_name"),
      width: 120,
      headerClassName: "employee-table-header",
    },
    {
      field: "col3",
      headerName: t("email"),
      width: 350,
      headerClassName: "employee-table-header",
    },
    {
      field: "col4",
      headerName: t("company_name"),
      width: 250,
      headerClassName: "employee-table-header",
    },
    {
      field: "col5",
      headerName: t("member_since"),
      width: 180,
      headerClassName: "employee-table-header",
    },
    {
      field: "col6",
      headerName: t("delete"),
      width: 500,
      headerClassName: "employee-table-header",
      renderCell: renderDetailsButton,
      onCellClick: () => removeEmployee(this),
    },
  ];

  const tableStyle = {
    boxShadow: 1,

    "& .MuiDataGrid-cell:hover": {
      color: "primary.main",
    },
  };

  return (
    <div style={{ marginLeft: "5%", marginRight: "5%" }}>
      <Grid container spacing={2}>
        {/* Add employee btn */}
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          item
          xs={12}
        >
          <AddEmployeePopup
            updateBusinessEmployeesTable={updateBusinessEmployeesTable}
          ></AddEmployeePopup>
        </Grid>

        {/* Employee table */}
        <Grid item xs={12} style={{ height: 495 }}>
          {employeesTableRows && (
            <DataGrid
              onCellClick={removeEmployee}
              sx={tableStyle}
              rows={employeesTableRows}
              columns={tableHeaders}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Business;
