import React, { useState } from "react";
import { SignInForm } from "./SignInForm.jsx";
import { ForgotPassword } from "./ForgotPassword";

export const Login = () => {
  const [forgotPassword, setForgotPassword] = useState(false);
  const toggleForgotPassword = () => setForgotPassword(!forgotPassword);

  if (!forgotPassword) {
    return (
      <>
        <SignInForm toggleForgotPassword={toggleForgotPassword}></SignInForm>
      </>
    );
  }

  if (forgotPassword) {
    return (
      <>
        <ForgotPassword
          toggleForgotPassword={toggleForgotPassword}
        ></ForgotPassword>
      </>
    );
  }
};
