import * as React from 'react';
import { Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Button } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import { API } from '../../utils/api';
import InfoIcon from '@mui/icons-material/Info';

const LocationCard = ({ location, businessId, setLocationsData }) => {
  // remove location
  const removeLocation = async (locationId, businessId) => {
    const response = await API.removeLocationFromBusiness({
      businessId: businessId,
      locationId: location.id,
    });

    if (response.status === 200) {
      setLocationsData(response.data);
    } else {
      console.log('error', response);
    }
  };

  return (
    <>
      {location && (
        <div sx={{ maxHeight: 90, padding: 0, minWidth: 270 }}>
          <Button
            onClick={() => removeLocation(location.id, businessId)}
            style={{ float: 'right' }}
          >
            <DeleteIcon style={{ fontSize: '15px', color: 'red' }}></DeleteIcon>
          </Button>
          {location.connect_status === 'pending' && (
            <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <InfoIcon style={{ fontSize: '15px', color: "rgb(255,171,0)", marginRight:'5px' }} /><p style={{ fontSize: '15px', color: "rgb(255,171,0)" }} >pending</p>
            </div>
            </>
          )}
          <Typography variant="h6" component="div">
            {location.title}
          </Typography>
          <Typography component="div">
            {location.street}, {location.number}
          </Typography>
          <Typography component="div">
            {location.zip}, {location.city}
          </Typography>
          <Divider
            style={{ borderBottom: '1px solid lightgrey', minWidth: '245px' }}
          ></Divider>
        </div>
      )}
    </>
  );
};

export default LocationCard;
