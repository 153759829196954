/* eslint-disable */
import Cookies from "js-cookie";
import { useState } from "react";
import { Image } from "./styles";
import { API } from "../../utils/api";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import { signInFormTemplate } from "../../forms/signInFormTemplate";
import { validationsFor } from "../../validations/validations";
import logo from "../../images/connect-logo.png";
import { renderFormFields } from "../../utils/misc";
import { Formik, Form, useFormik } from "formik";
import { Box } from "@material-ui/core";
import { t } from "../../translationHelper";

export const SignInForm = (props) => {
  const [signInError, setSignInError] = useState(false);
  const validationSchema = validationsFor("SIGN_IN");

  const initialFormValues = {
    email: "",
    password: "",
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      handleSubmit({ spotter: values });
    },
  });

  const onSubmit = async (values) => {
    handleSubmit({ spotter: values });
  };
  const template = signInFormTemplate(formik);

  const handleSubmit = async (values) => {
    const res = await signInSpotter(values);

    if (res.auth_token) {
      setSignInError(false);
      Cookies.set("adToken", res.auth_token);
      window.location.reload(false);
    } else if (res.message) {
      setSignInError(true);
    }
  };

  const signInSpotter = async (values) => {
    try {
      const data = await API.signIn(values);
      return data;
    } catch (error) {
      console.log(`error: `, error);
    }
  };

  // fix this
  const renderSignInError = () => (
    <Typography
      align="center"
      component="h3"
      variant="h6"
      style={{ color: "#F50157" }}
    >
      {t("incorrect_password_or_email")}
    </Typography>
  );

  const classes = useStyles();
  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <div className={classes.paper}>
          <Grid>
            <Box m={4} />
            <Image src={logo} alt="smslogo" />
          </Grid>

          <Box m={6} />
          <Formik>
            <Form
              className={classes.form}
              style={{ width: "40rem" }}
              onSubmit={formik.handleSubmit}
              noValidate
            >
              {signInError && renderSignInError()}

              {renderFormFields(template)}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={`full-button ${classes.submit}`}
                onSubmit={handleSubmit}
              >
                {t("login")}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Button
                    className={classes.floatright}
                    onClick={() => props.toggleForgotPassword()}
                  >
                    {t("forgot_password")}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </div>
      </Container>
    </>
  );
};

// Styles
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  floatright: {
    float: "right",
  },
}));
