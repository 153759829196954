import styled from '@emotion/styled';

export const Image = styled.img`
  width: 300px;
  height: auto;
`;

export const Grid = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr;
  margin-bottom: 1rem;
`;
