import { useApi } from "../../hooks/useApi";
import { useState } from "react";
import { baseUrl } from "../../utils/api";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import { H1 } from "../../components/reusable/Heading/index";
import { truncateString } from "../../utils/misc";
import { dateFormat } from "../../utils/misc";
import { t } from "../../translationHelper";
import styled from "@emotion/styled";
import { color } from "../../theme";

const Location = styled.span`
  color: ${color.spaceLight};
  font-size: 10px;
  font-style: italic;
  padding-left: 10px;
`;

const Card = ({ title, amount, createdAt, ...props }) => {
  return (
    <>
      <Grid
        style={{
          marginBottom: "25px",
          width: "100%",
        }}
      >
        <Grid>
          <Grid>
            <p style={{ fontWeight: "700" }}>{title}</p>{" "}
            {props.location && (
              <Location>{truncateString(props.location, 30)}</Location>
            )}
          </Grid>
          <Grid>{createdAt}</Grid>
          <Grid>{amount}</Grid>
        </Grid>
      </Grid>
    </>
  );
};

export const Invoice = ({ state, locale }) => {
  return (
    <>
      <a
        style={{
          position: "relative",
          top: "-55px",
          float: "right",
        }}
        href={`${baseUrl}v1/spotters/invoice/${state.id}.pdf?spotter_id=${state.spotter.id}&invoice_num=${state.invoice_number}`}
      >
        <Button variant="outlined">{t("download")}</Button>
      </a>
    </>
  );
};

const Invoices = () => {
  const [data] = useState([]);

  const [invoices] = useApi("fetchInvoices");

  return (
    <>
      <H1 style={{ marginBottom: "50px" }}>{t("invoices")}</H1>
      <Grid container spacing={3}></Grid>
      <Grid>
        {data &&
          data.invoices &&
          data.invoices.map((invoice, i) => {
            return (
              <Card
                key={i}
                amount={<Invoice state={invoice} locale={"nl"} />}
                title={`# ` + invoice.invoice_number}
                createdAt={dateFormat("nl", invoice.date)}
              />
            );
          })}
      </Grid>
    </>
  );
};

export default Invoices;
