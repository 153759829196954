import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { Tabs, Tab, Grid } from "@material-ui/core";
import logo from "../../../images/connect-logo.png";
import { Image } from "../../Login/styles";
import { t } from "../../../translationHelper";
import AccountMenu from "../../reusable/Menu/index";

const Wrapper = styled.div`
  @font-face {
    font-family: "Gilroy-Regular";
    src: url("./fonts/Gilroy-Regular.woff2") format("woff2");
  }
`;

export const Desktop = ({ signOut, translations }) => {
  const navigate = useNavigate();
  const location = useLocation();

  translations = {
    navbar: [
      {
        route: "",
        label: "Profile",
      },
      {
        route: "business",
        label: "Business",
      },
      {
        route: "edit",
        label: "Edit",
      },

      {
        route: "link",
        label: "...",
      },
    ],
    sign_out: "Sign out",
  };

  const initial = () => {
    if (location.pathname === "/") {
      return 0;
    } else if (location.pathname === "/edit") {
      return 1;
    } else if (location.pathname === "/invoices") {
      return 2;
    } else {
      return null;
    }
  };

  const [value, setValue] = React.useState(initial);

  useEffect(() => {
    setValue(initial);
  }, [initial]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid item>
        {" "}
        <Grid
          style={{
            margin: "30px 0px",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: "30px",
          }}
        >
          <Grid style={{ padding: "0px", margin: "0px" }}>
            <Image
              className="navbar-logo"
              src={logo}
              alt="smslogo"
              style={{ width: "180px", height: "auto" }}
            />
          </Grid>
          <Grid>
            <Wrapper>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="navbar"
                TabIndicatorProps={{
                  style: { background: "#172c53", width: "33%" },
                }}
              >
                <Tab
                  aria-label="person"
                  label={t("profile")}
                  onClick={() => navigate("/")}
                />
                <Tab
                  aria-label="edit"
                  label={t("edit")}
                  onClick={() => navigate("/edit")}
                />
                <Tab
                  aria-label="invoices"
                  label={t("invoices")}
                  onClick={() => navigate("/invoices")}
                />
              </Tabs>
            </Wrapper>
          </Grid>
          <Grid style={{ padding: "5px", display: "flex" }}>
            <AccountMenu />
          </Grid>
        </Grid>{" "}
      </Grid>
    </>
  );
};
