import { API } from "../../utils/api";
import { useApi } from "../../hooks/useApi";
import Button from "@material-ui/core/Button";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { renderFormFields } from "../../utils/misc";
import { Formik, Form, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { H1, H2 } from "../../components/reusable/Heading";
import { validationsFor } from "../../validations/validations";
import SnackbarAlert from "../../components/reusable/SnackbarAlert";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { simulateMouseClick } from "../../utils/misc";
import {
  editFormTemplate,
  editOwnerTemplate,
} from "../../forms/editFormTemplate";
import { LocationList } from "../../components/LocationList";
import Business from "../../components/Business/index";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { t } from "../../translationHelper";

const Edit = () => {
  const [open, setOpen] = React.useState(true);
  const [business] = useApi("loadBusiness");
  const [locations, setLocations] = useState([]);
  const [alert, setAlert] = useState(null);
  const [initialFormValues, setInitialFormValues] = useState({
    name: "",
    number: "",
    street: "",
    zip: "",
    city: "",
    email: "",
    first_name: "",
    last_name: "",
  });
  const [showSpinner, setShowSpinner] = React.useState(false);

  const validationSchemaEdit = validationsFor("EDIT_PROFILE");
  const validationSchemaOwner = validationsFor("OWNER");

  // import router
  const router = useLocation();

  useEffect(() => {
    if (router.search) {
      const param = router.search.split("=")[0].replace("?", "");
      const element = document.getElementById(param);

      if (element) {
        simulateMouseClick(element);
      }
    }
  }, [router]);

  const [initialFormValuesOwner, setInitialFormValuesOwner] = useState({
    email: "",
    first_name: "",
    last_name: "",
  });

  const formikOwner = useFormik({
    initialValues: initialFormValuesOwner,
    validationSchema: validationSchemaOwner,
    enableReinitialize: true,

    onSubmit: async (values) => {
      const body = {
        business: {
          name: values.name,
          location: {
            street: values.street,
            number: values.number,
            zip: values.zip,
            city: values.city,
          },
          owner: {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
          },
        },
      };

      const req = await API.updateBusiness(body, business.id);

      if (req.status === 200) {
        const data = JSON.parse(req.data);
        setAlert({
          variant: "success",
          message: req.message,
          open: true,
        });
        setOpen(true);
      } else if (req.status === 400) {
        setAlert({
          variant: "error",
          message: req.message,
          open: true,
        });
      }

      setShowSpinner(false);
    },
  });

  useEffect(() => {
    if (business && business.locations) {
      setLocations(business.locations);
      setInitialFormValuesOwner({
        email: business.owner.email,
        first_name: business.owner.first_name,
        last_name: business.owner.last_name,
      });
    }
  }, [business]);

  // Form submit
  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: validationSchemaEdit,
    enableReinitialize: true,

    onSubmit: async (values) => {
      const body = {
        business: {
          name: values.name,
          location: {
            street: values.street,
            number: values.number,
            zip: values.zip,
            city: values.city,
          },
          owner: {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
          },
        },
      };

      const req = await API.updateBusiness(body, business.id);

      if (req.status === 200) {
        const data = JSON.parse(req.data);
        setLocations(data);
        setAlert({
          variant: "success",
          message: req.message,
          open: true,
        });
        setOpen(true);
      } else if (req.status === 400) {
        setAlert({
          variant: "error",
          message: req.message,
          open: true,
        });
      }
      setShowSpinner(false);
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setInitialFormValues({ name: " business.name " });
  }, [business]);
  const businessTemplate = editFormTemplate(formik);
  const ownerTemplate = editOwnerTemplate(formikOwner);

  const classes = useStyles();

  const displayAlert = (alert) => (
    <SnackbarAlert
      handleClose={handleClose}
      display={open}
      content={alert.message}
    ></SnackbarAlert>
  );

  return (
    <>
      <H1>{t("business_profile")}</H1>

      <hr></hr>
      <SnackbarAlert></SnackbarAlert>
      <Formik>
        <Form
          className={classes.form}
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <Accordion>
            <AccordionSummary
              className="acc-btn"
              expandIcon={
                <ArrowForwardIosSharpIcon className={`${classes.mr15}`} />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <H2
                className={`${classes.fiftyrem} ${classes.heading}`}
                id="personal"
              >
                {t("personal_information")}{" "}
                <EditIcon className={classes.floatright} />
              </H2>
            </AccordionSummary>
            <AccordionDetails>
              {alert && displayAlert(alert)}
              <div style={{ marginRight: "5%", marginLeft: "5%" }}>
                {renderFormFields(ownerTemplate)}
              </div>
              <Button
                onClick={() => {
                  setShowSpinner(true);
                }}
                type="submit"
                variant="contained"
                color="primary"
                className={`${classes.submit} ${classes.floatright} outlined-button`}
              >
                {t("save")}{" "}
                {showSpinner && (
                  <CircularProgress
                    size={20}
                    style={{ color: "white", marginLeft: "12px" }}
                  />
                )}{" "}
              </Button>
            </AccordionDetails>
          </Accordion>{" "}
          <Accordion>
            <AccordionSummary
              className="acc-btn"
              expandIcon={<ArrowForwardIosSharpIcon className={classes.mr15} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <H2 className={`${classes.fiftyrem}`} id="business">
                {t("locations")} <EditIcon className={classes.floatright} />
              </H2>
            </AccordionSummary>
            <AccordionDetails>
              {alert && displayAlert(alert)}
              <div style={{ marginRight: "5%", marginLeft: "5%" }}>
                {renderFormFields(businessTemplate)}
              </div>
              {business && business.locations && (
                <LocationList
                  locations={locations}
                  businessId={business.id}
                  setLocationsData={setLocations}
                ></LocationList>
              )}
            </AccordionDetails>
          </Accordion>{" "}
          <Accordion>
            <AccordionSummary
              className="acc-btn"
              expandIcon={
                <ArrowForwardIosSharpIcon className={`${classes.mr15}`} />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <H2
                className={`${classes.fiftyrem} #{classes.heading}`}
                id="employee"
              >
                {t("employees")} <EditIcon className={classes.floatright} />
              </H2>
            </AccordionSummary>
            <AccordionDetails>
              <Business />
            </AccordionDetails>
          </Accordion>
        </Form>
      </Formik>
      <div style={{ marginBottom: "1000px" }}></div>
    </>
  );
};

export default Edit;

// Styles
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  heading: { width: "50rem", float: "left" },
  fiftyrem: { width: "100%" },
  floatright: { float: "right" },
  floatleft: { float: "left" },
  mr15: { marginRight: "15px" },
  ml15: { marginLeft: "15px" },
}));
