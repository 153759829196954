import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { renderFormFields } from "../../utils/misc";
import { addLocationFormTemplate } from "../../forms/addLocationFormTemplate";
import { validationsFor } from "../../validations/validations";
import { useFormik } from "formik";
import { API } from "../../utils/api";
import SnackbarAlert from "../reusable/SnackbarAlert";
import CircularProgress from "@mui/material/CircularProgress";
import { t } from "../../translationHelper";

function AddLocationPopup({ setLocationsData, businessId }) {
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState(null);
  const [showSpinner, setShowSpinner] = React.useState(false);

  const displayAlert = (alert) => (
    <SnackbarAlert
      handleClose={handleClose}
      display={alert.open}
      content={alert.message}
    ></SnackbarAlert>
  );

  const validationSchema = validationsFor("ADD_LOCATION");

  const initialFormValues = {
    title: "",
    street: "",
    number: "",
    city: "",
    zip: "",
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const res = await API.addLocationToBusiness({
        businessId: businessId,
        location: values,
      });

      if (res.status === 200) {
        setLocationsData(res.data);
        setOpen(false);
        setAlert({
          variant: "success",
          //TODO: translation text
          message:
            "Location added successfully. Someone from our team will approve it shortly.",
          open: true,
        });
        setShowSpinner(false);
      } else {
        console.log("error", res);
        setAlert({
          variant: "error",
          message: "Something went wrong", //TODO: translation text
          open: true,
        });
        setShowSpinner(false);
      }
    },
  });

  const template = addLocationFormTemplate(formik);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAlert({
      variant: "success",
      message: "",
      open: false,
    });
  };

  return (
    <div>
      {" "}
      <Button
        onClick={handleClickOpen}
        variant="outlined"
        style={{ float: "right", marginTop: "60px" }}
        className="outlined-button"
      >
        {t("add_location")}{" "}
        <GroupAddIcon style={{ marginLeft: "15px" }}></GroupAddIcon>
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("add_new_location")}</DialogTitle>
        <DialogContent style={{ marginTop: "5px", marginBottom: "25px" }}>
          <DialogContentText style={{ marginBottom: "25px" }}>
            {t("add_location_information")}
          </DialogContentText>
          {renderFormFields(template)}
        </DialogContent>
        <DialogActions style={{ marginTop: "10px", marginBottom: "10px" }}>
          <Button className="cancel-location-invite-btn" onClick={handleClose}>
            {t("cancel")}
          </Button>
          <Button
            className="invite-location-btn"
            variant="contained"
            onClick={() => {
              formik.handleSubmit();
              setShowSpinner(true);
            }}
          >
            {t("add")}

            {showSpinner && (
              <CircularProgress
                size={20}
                style={{ color: "white", marginLeft: "12px" }}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
      {alert && displayAlert(alert)}
    </div>
  );
}

export default AddLocationPopup;
