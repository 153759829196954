import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { renderFormFields } from "../../utils/misc";
import { addEmployeeFormTemplate } from "../../forms/addEmployeeFormTemplate";
import { validationsFor } from "../../validations/validations";
import { useFormik } from "formik";
import { API } from "../../utils/api";
import SnackbarAlert from "../reusable/SnackbarAlert";
import CircularProgress from "@mui/material/CircularProgress";
import { t } from "../../translationHelper";

function AddEmployeePopup({ updateBusinessEmployeesTable }) {
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState(null);

  const [showSpinner, setShowSpinner] = React.useState(false);

  const displayAlert = (alert) => (
    <SnackbarAlert
      handleClose={handleClose}
      display={alert.open}
      content={alert.message}
    ></SnackbarAlert>
  );

  const validationSchema = validationsFor("ADD_EMPLOYEE");

  const initialFormValues = {
    email: "",
    first_name: "",
    last_name: "",
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const res = await API.addEmployeeToBusiness({
        employee: values,
        is_connect: true,
      });

      if (res.status === 200) {
        updateBusinessEmployeesTable(res.data);
        setOpen(false);
        setAlert({
          variant: "success",
          message: "Employee added successfully",
          open: true,
        });
      } else {
        console.log("error", res);
        setAlert({
          variant: "error",
          message: "Something went wrong",
          open: true,
        });
      }
    },
  });

  const template = addEmployeeFormTemplate(formik);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {" "}
      {/* TODO: translate */}
      <form>
        <Button
          onClick={handleClickOpen}
          variant="outlined"
          className="outlined-button"
        >
          {t("add_employee")}{" "}
          <GroupAddIcon style={{ marginLeft: "15px" }}></GroupAddIcon>
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{t("add_new_employee")}</DialogTitle>
          <DialogContent style={{ marginTop: "5px", marginBottom: "25px" }}>
            <DialogContentText style={{ marginBottom: "25px" }}>
              {t("add_employee_information")}
            </DialogContentText>
            {renderFormFields(template)}
          </DialogContent>
          <DialogActions style={{ marginTop: "10px", marginBottom: "10px" }}>
            <Button
              className="cancel-employee-invite-btn"
              onClick={handleClose}
            >
              {t("cancel")}
            </Button>
            <Button
              className="invite-employee-btn"
              variant="contained"
              onClick={() => {
                formik.handleSubmit();
                setShowSpinner(true);
              }}
            >
              {t("invite")}{" "}
              {showSpinner && (
                <CircularProgress
                  size={20}
                  style={{ color: "white", marginLeft: "12px" }}
                />
              )}{" "}
            </Button>
          </DialogActions>
        </Dialog>
      </form>
      {alert && displayAlert(alert)}
    </div>
  );
}

export default AddEmployeePopup;
