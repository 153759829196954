import { css } from "@emotion/react";
import { fontWeight, color, space, radius } from "./theme";
import { baseTextStyles } from "./components/reusable/Text";

export const globalStyles = css`
  @font-face {
    font-family: 'Ariel';
    format('woff2'),
    font-weight: ${fontWeight.regular};
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Ariel';
    format('woff2'),
    font-weight: ${fontWeight.regular};
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Ariel';
    format('woff2'),
    font-weight: ${fontWeight.semiBold};
    font-style: normal;
    font-display: swap;
  }


  @font-face {
    font-family: 'Ariel';
    format('woff2'),
    font-weight: ${fontWeight.bold};
    font-style: normal;
    font-display: swap;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    border: 0;
  }

  html {
    margin-left: calc(100vw - 100%);
  }

  body {
    ${baseTextStyles};
  }

  code,
  var,
  kbd,
  samp,
  tt,
  dir,
  listing,
  plaintext,
  xmp,
  abbr,
  acronym,
  blockquote,
  input,
  textarea,
  q {
    hyphens: none;
  }

  a {
    text-decoration: none;
    color: ${color.earth};
  }

  button {
    padding: 0;
    margin: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
    border-radius: ${radius.md};

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 ${space[4]} ${color.earthFocusAlpha};
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  ol,
  ul {
    list-style: none;
  }

  img,
  video {
    max-width: 100%;
  }

  img {
    border-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:after,
  blockquote:before,
  q:after,
  q:before {
    content: '';
    content: none;
  }

  .employee-table-header {
    background-color: #006392;
    color: white;
  }

  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700 !important;
  }

  .employee-table-header {
    background-color: #172c53 !important;
  }
  

  .MuiAccordion-rounded {
    all: unset;
  }

  .rotate {
    -ms-transform: rotate(280deg);
    -webkit-transform: rotate(280deg);
    transform: rotate(280deg);
  }

  .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
    flex-direction: row-reverse;
  }

  .acc-btn {
    flex-direction: row-reverse;
    margin-right: 15px;
  }

  .acc-bt {
    transform: rotate(90deg) !important;
  }

  .MuiTabs-flexContainer {
    justify-content: space-between;
  }

  .css-67l5gl {
    box-shadow: none !important;
    position: static !important;
  }

  .css-1qs1e7e {
    position: static !important;
    box-shadow: none !important;
  }

  .app-container {
    display: grid;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 1.5rem auto;
    margin-bottom: 0px;
    max-width: 55rem;
  }
`;
