import { Grid } from "@mui/material";
import { useApi } from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";
import Rank from "../../components/reusable/Rank";
import { useEffect } from "react";
import Stats from "../../components/reusable/Stats";
import Graph from "../../components/reusable/Graph";
import { Login } from "../../components/Login/index";
import { Text } from "../../components/reusable/Text";
import Avatar from "../../components/reusable/Avatar";
import { H4 } from "../../components/reusable/Heading";
import { Spinner } from "../../icons/Spinner";
import { t } from "../../translationHelper";

const Profile = () => {
  const {
    context: { hasCookie },
  } = useAuth();

  const [spotterData] = useApi("loadProfile");
  const [businessData] = useApi("loadBusinessData");

  // useEffect(() => {
  //   if (spotterData) {
  //     if (!Cookies.get("lang")) Cookies.set("lang", spotterData.country_code);

  //     i18n.changeLanguage(spotterData.country_code);
  //   }
  // }, []);

  if (!hasCookie) {
    return <Login />;
  }

  if (hasCookie && spotterData) {
    return (
      <>
        {" "}
        {/* Spotter Avatar */}
        <Grid
          style={{ justifyContent: "center", marginTop: "50px" }}
          alignItems="center"
          container
          direction="row"
          item
          xs={12}
          className="profile-wrapper"
        >
          {spotterData && <Avatar alt="S L" data={spotterData} />}
        </Grid>
        {/* Spotter Info */}
        <Grid>
          <Grid
            style={{ justifyContent: "center", marginBottom: "40px" }}
            alignItems="center"
            container
            direction="row"
            item
            xs={12}
          >
            {" "}
            <Grid>
              <H4 style={{ margin: "0px" }}>{spotterData.email}</H4>
              <Text>
                {`${spotterData.first_name} ${spotterData.last_name}`}{" "}
              </Text>
            </Grid>
          </Grid>

          {/* Spotter Ranking */}
          <Grid>
            <Rank
              position={spotterData.ranking}
              points={spotterData.points}
              balance={spotterData.balance}
            />
          </Grid>
          <br></br>
          {businessData && (
            <>
              <Grid>
                <Stats
                  employees={businessData.business_stats.number_employees}
                  locations={businessData.business_stats.number_locations}
                  total={businessData.business_stats.total_spots_count}
                  monthly={businessData.business_stats.monthly_spots_count}
                  weekly={businessData.business_stats.weekly_spots_count}
                />
              </Grid>

              <br></br>

              <Grid style={{ marginBottom: "350px" }}>
                <Graph data={businessData.business_stats.spots_per_day} />
              </Grid>
            </>
          )}
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <Spinner
          style={{ marginLeft: "38%", marginTop: "25%" }}
          size={150}
        ></Spinner>
      </>
    );
  }
};

export default Profile;
