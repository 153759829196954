import { sharedProps } from "../utils/misc";
import { t } from "../translationHelper";

export const addEmployeeFormTemplate = (formik) => {

  return {
    title: "Add employee form",
    Fields: [
      {
        name: "email",
        type: "text",
        title: "email",
        id: "email",
        label: t("email"),
        autoComplete: "email",
        ...sharedProps,
        value: formik.values.email,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.email),
        helperText: formik.errors.email,
      },
      {
        name: "first_name",
        type: "text",
        title: "first_name",
        id: "first_name",
        label: t("first_name"),
        autoComplete: "first_name",
        ...sharedProps,
        value: formik.values.first_name,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.first_name),
        helperText: formik.errors.first_name,
      },

      {
        name: "last_name",
        type: "text",
        title: "last_name",
        id: "last_name",
        label: t("last_name"),
        autoComplete: "last_name",
        ...sharedProps,
        value: formik.values.last_name,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.last_name),
        helperText: formik.errors.last_name,
      },
    ],
  };
};
