import "./index.css";

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./hooks/useAuth";
import { Desktop as Navbar } from "../src/components/Navbar/Desktop/index";
import { BaseStyles } from "./components/reusable/BaseStyles";

// Pages component containers
import Edit from "./pages/edit/index";
import Profile from "./pages/profile/index";
import Invoices from "./pages/invoices/index";
import Signup from "./pages/signup/index";

import Cookies from "js-cookie";

// get cookies
const token = Cookies.get("spToken");

// pages go here
function App() {
  return (
    <div className="App">
      <div className="container">
        <AuthProvider>
          <BaseStyles />
          <BrowserRouter>
            {token && <Navbar></Navbar>}

            <Routes>
              <Route path="/" element={<Profile />} />
              <Route path="edit" element={<Edit />} />
              <Route path="invoices" element={<Invoices />} />
              <Route path="signup" element={<Signup />} />
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </div>
    </div>
  );
}

export default App;
