import { useState, useEffect } from "react";
import { API } from "../utils/api";

export function useApi(method, ...params) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setError(null);
    setIsLoading(true);
    try {
      setData(await API[method](...params));
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return [data, isLoading, error];
}
