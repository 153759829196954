import { sharedProps } from "../utils/misc";
import { t } from "../translationHelper";

export const addLocationFormTemplate = (formik) => {
  return {
    title: "Add location template",
    Fields: [
      {
        name: "title",
        type: "text",
        title: "title",
        id: "title",
        label: t("title"),
        autoComplete: "title",
        ...sharedProps,
        value: formik.values.title,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.title),
        helperText: formik.errors.title,
      },
      {
        name: "street",
        type: "text",
        title: "street",
        id: "street",
        label: t("street"),
        autoComplete: "street",
        ...sharedProps,
        value: formik.values.street,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.street),
        helperText: formik.errors.street,
      },
      {
        name: "number",
        type: "text",
        title: "number",
        id: "number",
        label: t("number"),
        autoComplete: "number",
        ...sharedProps,
        value: formik.values.number,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.number),
        helperText: formik.errors.number,
      },
      {
        name: "city",
        type: "text",
        title: "city",
        id: "city",
        label: t("city"),
        autoComplete: "city",
        ...sharedProps,
        value: formik.values.city,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.city),
        helperText: formik.errors.city,
      },
      {
        name: "zip",
        type: "text",
        title: "zip",
        id: "zip",
        label: t("zip"),
        autoComplete: "zip",
        ...sharedProps,
        value: formik.values.zip,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.zip),
        helperText: formik.errors.zip,
      },
    ],
  };
};
