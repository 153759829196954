import { useState, useRef, useEffect } from "react";
import { AvatarWrapper, Image, CameraWrapper } from "./styles";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import styled from "styled-components";
import { baseUrl } from "../../../utils/api";

export const Item = styled.div`
  display: grid;
  width: 100%;
  justify-content: ${(props) => props.alignment};
  align-items: ${(props) => props.alignment};
`;

const Avatar = ({ data }) => {
  const [reload, setReload] = useState(false);

  const cameraRef = useRef();
  const inputRef = useRef();

  const handleUpload =
    ({ id }) =>
    async (e) => {
      const file = e.target.files[0];

      if (file) {
        const formData = new FormData();

        formData.append("file", file);
        formData.append("name", "name param");

        await fetch(`${baseUrl}v1/spotters/update_profile_photo`, {
          method: "POST",
          credentials: "include",
          headers: {
            "Access-Control-Allow-Origin": baseUrl,
            "Acces-Control-Allow-Credentials": "true",
            processData: false,
            contentType: false,
            Accept: "image/jpg",
          },
          body: formData,
        }).then((response) => {
          if (response.ok) {
            window.location.reload();
            setReload(true);
          }
        });
      }
    };

  useEffect(() => {
    cameraRef.current.addEventListener("click", () => {
      inputRef.current.click();
    });
  }, [cameraRef]);

  return (
    <>
      <AvatarWrapper>
        <Image
          src={
            "https://www.smartspotter.com/application/themes/smartspotter/img/logo_icon.png"
            // data && data.profile_photo
            //   ? `${data.profile_photo.url.replace(
            //       'dev',
            //       'images'
            //     )}?time=${new Date().getTime()} `
            //   : null
          }
          als="avatar"
        />
        <CameraWrapper ref={cameraRef}>
          <CameraAltIcon />
        </CameraWrapper>
      </AvatarWrapper>
      <input
        name="profile_photo"
        accept="image/jpeg/png"
        type="file"
        autoComplete="off"
        onChange={handleUpload(data)}
        style={{ display: "none" }}
        ref={inputRef}
      />
    </>
  );
};

export default Avatar;
