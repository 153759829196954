import {
  signupFormTemplate,
  editOwnerTemplate,
} from "../../forms/signupFormTemplate";
import { API } from "../../utils/api";
import { useState } from "react";
import { Box } from "@material-ui/core";
import { Container } from "@mui/material";
import { t } from "../../translationHelper";
import { renderFormFields } from "../../utils/misc";
import { useFormik, Formik, Form } from "formik";
import { H1, H2, H4 } from "../../components/reusable/Heading";
import { validationsFor } from "../../validations/validations";
import { Grid, Button, Typography } from "@material-ui/core";
import { Steps, StepsProvider, useSteps } from "react-step-builder";
import SnackbarAlert from "../../components/reusable/SnackbarAlert/index";
import QuestionTree from "../../components/QuestionTree/index";

const Signup = () => {
  return (
    <>
      <StepsProvider>
        <SignupWizard />
      </StepsProvider>
    </>
  );
};

const SignupWizard = () => {
  const { next, prev } = useSteps();

  const { sg } = window.location.search;

  const [alert, setAlert] = useState(null);
  const [open, setOpen] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  // export this to misc.js
  const displayAlert = (alert) => (
    <>
      {" "}
      <SnackbarAlert
        handleClose={handleClose}
        display={open}
        variant={alert.variant}
        content={alert.message}
      ></SnackbarAlert>
    </>
  );

  const [owner, setOwner] = useState({
    email: ``,
    first_name: "",
    last_name: "",
  });

  const [business, setBusiness] = useState({
    name: "",
    number: "",
    street: "",
    zip: "",
    city: "",
  });

  const [questionnaireValues, setQuestionnaireValues] = useState([]);

  const handleOwner = async (values) => {
    values.country_code = "nl";
    const res = await API.signUpOwner({ spotter: values, is_connect: true });

    if (res.status === 200) {
      setOwner(res.data);
      next();
    } else if (res.status === 400) {
      // TODO: fix color, move to top-right, add handleClose
      setAlert({
        variant: "error",
        message: "Please choose a different e-mail.",
        open: true,
      });

      setOpen(true);
    }
  };

  const handleBusiness = async (values) => {
    const data = sg
      ? {
          business: {
            name: values.name,
            owner_email: owner.email,
            location: {
              street: values.street,
              number: values.number,
              zip: values.zip,
              city: values.city,
            },
            segment_group: sg,
          },
        }
      : {
          business: {
            name: values.name,
            owner_email: owner.email,
            location: {
              street: values.street,
              number: values.number,
              zip: values.zip,
              city: values.city,
            },
          },
        };
    const res = await API.signUpBusiness(data);

    if (res.status === 200) {
      setBusiness(JSON.parse(res.data));
      next();
    } else if (res.errors) {
      setAlert({
        variant: "error",
        message: "Please choose a different business name.",
        open: true,
      });
      setOpen(true);
    }
  };

  const addQuestionnaireToBusiness = () => {
    const data = {
      business_id: business.id,
      signup_questionnaire_data: questionnaireValues,
    };
    API.addQuestionnaireToBusiness(data);
  };

  const handleRegistration = (e) => {
    e.preventDefault();
    addQuestionnaireToBusiness();
    window.location.href = "/app_links";
  };

  function handleQuestionnaire(values) {
    setQuestionnaireValues([...questionnaireValues, values]);
  }

  const validationSchemaSignup = validationsFor("BUSINESS");
  const validationSchemaOwner = validationsFor("OWNER");

  return (
    <>
      <Steps>
        {/* <Step
          next={next}
          prev={prev}
          content={`This is the content description. Some text needs to replace this here. This is the content description. Some text needs to replace this here. This is the content description. Some text needs to replace this here.`}
          validations={validationSchemaOwner}
          template={editOwnerTemplate}
          handleStep={handleOwner}
          initialFormValues={owner}
          heading={t("registration")}
          subHeading={t("personal_information")}
          description={t("personal_information_description")}
        />
        <Step
          next={next}
          prev={prev}
          content={`This is the content description. Some text needs to replace this here. This is the content description. Some text needs to replace this here. This is the content description. Some text needs to replace this here.`}
          validations={validationSchemaSignup}
          template={signupFormTemplate}
          handleStep={handleBusiness}
          initialFormValues={business}
          heading={t("registration")}
          subHeading={t("personal_information_2")}
          description={t("business_information_description")}
        /> */}
        <Questionnaire
          next={next}
          prev={prev}
          handleQuestionnaire={handleQuestionnaire}
        />
        <Result
          prev={prev}
          business={business}
          owner={owner}
          questionnaireValues={questionnaireValues}
          handleRegistration={handleRegistration}
        />
      </Steps>
      {alert && displayAlert(alert)}
    </>
  );
};
export default Signup;

const Result = ({
  owner,
  business,
  prev,
  questionnaireValues,
  handleRegistration,
}) => {
  if (!business || !owner) return null;
  const location = business.locations[0];

  return (
    <Container>
      <H1>{t("overview")}</H1>
      <Box m={5}></Box>
      <H2>{t("personal_information")}</H2>
      <Typography> {`${owner.first_name} ${owner.last_name}`} </Typography>
      <Typography> {owner.email} </Typography>
      <Box m={5}></Box>

      <H2>{t("personal_information_2")}</H2>

      <Typography> {business.name} </Typography>
      <Typography> {`${location.street} ${location.number}`} </Typography>
      <Typography> {`${location.zip}, ${location.city}`} </Typography>
      <Box m={5}></Box>

      {questionnaireValues && <H2>Questionnare Responses</H2>}

      {questionnaireValues &&
        questionnaireValues.map((value) => (
          <>
            <H4> {value.q} </H4>
            <Typography> {value.a} </Typography>
          </>
        ))}

      <Button
        style={{ float: "right" }}
        color="primary"
        onClick={(e) => handleRegistration(e)}
      >
        {t("confirm")}
      </Button>
      <Button style={{ float: "right" }} color="primary" onClick={() => prev()}>
        {t("back")}
      </Button>
    </Container>
  );
};

const Step = ({
  prev,
  next,
  content,
  template,
  validations,
  initialFormValues,
  handleStep,
  heading,
  subHeading,
}) => {
  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: validations,
    enableReinitialize: true,

    onSubmit: async (values) => {
      handleStep(values);
    },
  });

  const formTemplate = template(formik);

  return (
    <>
      <Container container>
        <Grid item sx={12}>
          <H1>{heading}</H1>
        </Grid>
        <Box m={4}></Box>

        <Grid sx={12}>
          <H2>{subHeading}</H2>
        </Grid>
        <Box m={2}></Box>

        <Grid>
          <Typography>{content}</Typography>
        </Grid>
        <Box m={2}></Box>

        <Grid>
          {" "}
          <Formik>
            <Form onSubmit={formik.handleSubmit} noValidate>
              {renderFormFields(formTemplate)} <Box m={2}></Box>{" "}
              <Button style={{ float: "right" }} color="primary" type="submit">
                {t("next")}
              </Button>
              <Button style={{ float: "right" }} color="primary" onClick={prev}>
                {t("prev")}
              </Button>
            </Form>
          </Formik>
        </Grid>
      </Container>
    </>
  );
};

const Questionnaire = ({ next, handleQuestionnaire }) => {
  return (
    <>
      <Grid item sx={12}>
        {/* TODO: translate */}
        <H1>Registration</H1>
      </Grid>
      <Box m={4}></Box>

      <Grid sx={12}>
        {/* TODO: translate */}
        <H2>Signup Questionnaire</H2>
      </Grid>
      <Box m={2}></Box>
      <Grid>
        <Typography>
          Please answer a few more questions about your business. This will help
          us find you the best spots.{" "}
        </Typography>
      </Grid>
      <Box m={2}></Box>
      <QuestionTree next={next} handleQuestionnaire={handleQuestionnaire} />
    </>
  );
};
