import styled from '@emotion/styled';

export const Image = styled.img`
  border-radius: 50%;
  height: 140px;
  width: 140px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05), 0 0px 5px rgba(0, 0, 0, 0.3);
  padding: 0.25rem;
`;
export const AvatarWrapper = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border-style: solid;
  border-color: #ffffff;
  position: relative;
`;
export const CameraWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: -7px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: rgb(157, 189, 75);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05), 0 0px 5px rgba(0, 0, 0, 0.3);
`;
